import { useState, useRef, useEffect } from "react";
import './Recharge.less'
import diamondPng from "@/image/recharge/diamond.png";
import { methodOfPay } from "./const"
import { Dialog, Toast } from 'antd-mobile'
import { DialogShowHandler } from 'antd-mobile/es/components/dialog'
import { getPayMethod, getUserInfo, getProductList, postTopUp, createOrder } from "@/api/recharge";
import { getActivityList } from '@/api/activity'
import NavBar from '@/component/NavBar/NavBar';
import { jumpPage } from "@/utils/common";

const Recharge = () => {
  const handler = useRef<DialogShowHandler>()
  const [active, setActive] = useState(0)
  const [method, setMethod] = useState('Alipay')
  const [env] = useState(process.env.REACT_APP_ENV_VERSION)
  const inputRef = useRef(null);
  let ui = localStorage.getItem('userInfo')
  ui = ui && ui !== 'undefined' ? JSON.parse(localStorage.getItem('userInfo') as string) : {}
  const [userInfo, setUserInfo] = useState<any>(ui)
  const [listOfAmounts, setListOfAmounts] = useState([{
    amount: 0,
    description: "",
    diamond_num: 0,
    product_code: "",
  }])
  const [dailyTopUp, setDailyTopUp] = useState<string>('')
  const [aggre_money, setAggreMoney] = useState<number>(0)
  const [hasActivity, setHasActivity] = useState<string>('')

  useEffect(() => {
    getProductList().then(e=> {
      setListOfAmounts(e.list)
      setDailyTopUp(e.image_url)
      setAggreMoney(e.aggre_money)
    })

    getActivityList({ activity_type: 'LC' }).then(e => {
      setHasActivity(e[0]?.activity_code)
    }).catch(() => {})
    // if (!token) window.location.href = '/' 
  }, [])

  function getValue(input: any) {
    if (typeof input === 'object') return input.value
  }

  const accountDialog = () => {
    handler.current = Dialog.show({
      content: (
        <>
          <h3 className="dialog-title">充值账号</h3>
          <section>
            <input ref={inputRef} type="number" name="user_id" placeholder="请输入UU语音账户ID" />
          </section>
        </>
      ),
      // closeOnAction: true,
      actions: [
        [
          {
            key: 'cancel',
            text: '取消',
            onClick: () => {
              handler.current?.close()
            },
          },
          {
            key: 'confirm',
            text: '确定',
            bold: true,
            disabled: false,
            onClick: () => {
              const user_id = Number(getValue(inputRef.current))
              if (user_id) {
                getUserInfo(user_id).then((e:any) => {
                  // 存在localStorage里面是被充值账户的信息，而存在redux里面的是本账户的信息
                  localStorage.setItem('userInfo', JSON.stringify(e))
                  setUserInfo(e)
                  handler.current?.close()
                }).catch(() => {})
              } else Toast.show('请先填写账户ID')
              // setUserInfo({
              //   user_id: value,
              //   avatar: '',
              //   user_nickname: 'Rechard',
              // })
              // // console.log(value, 'value')
              // if (!value.length) Toast.show('用户ID不能为空！')
              // handler.current?.close()
            },
          },
        ],
      ],
    })
  }

  // 获取支付code
  const triggerGetPayMethod = () => {
    return getPayMethod(method === 'WeChat' ? 'wechat_miniapp' : 'h5').then(e => e);
  }

  // 支付宝支付
  const triggerTopUp = async () => {
    const data = {
      client_os: method === 'WeChat' ? 'wechat_miniapp' : 'h5',
      product_code: listOfAmounts[active].product_code,
      to_user_id: userInfo.user_id,
      // diamond_num: mount.value,
      // amount: money.value * 100,
    }

    const payMethod = await triggerGetPayMethod()
    const payResult = await postTopUp(data);

    if (payMethod?.asset_type_code && payMethod?.asset_product_code) {
      const { asset_type_code, asset_product_code } = payMethod;
      const { user_id, biz_no, amount, biz_notify_url, expire_at_time, third_user_id, auth_app_id } = payResult;

      if (method === 'WeChat') {
        // 正式版为release，体验版为trial，开发版为develop
        const queryString = `biz_no=${biz_no}&amount=${amount}&user_id=${userInfo.user_id}`;

        try {
          window.location.href = `weixin://dl/business/?appid=wx42296866733afa0c&path=pages/recharge/recharge&query=${encodeURIComponent(queryString)}&env_version=${env}`
        }catch (error:any) {
          Toast.show('请先安装最新版本的UU语音！')
        }

        return
      }

      createOrder({
        asset_type_code,
        asset_product_code,
        uid: user_id,
        biz_no,
        goods_name: `${listOfAmounts[active].diamond_num}钻石`,
        goods_desc: '',
        amount,
        biz_notify_url,
        expire_at_time,
        client_os: method === 'WeChat' ? 'wechat_miniapp' : 'h5',
        return_url: window.location.href,
        third_user_id,
        auth_app_id,
      }).then(e => {
        // 触发支付宝支付api
        try {
          // window.location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(e.url)}`
          if (e.alipay_response.url) window.location.href = `${e.alipay_response.url}`
        }catch (error:any) {
          Toast.show(error)
        }
      })
    } else {
      triggerGetPayMethod()
      Toast.show('支付方式获取中，请稍后再试！')
    }
  }

  const confirmPay = () => {
    handler.current = Dialog.show({
      content: (
        <>
          <h3 className="dialog-title">确认充值</h3>
          <section>
            <p>是否为ID为{userInfo.user_id}，昵称为 {userInfo.user_nickname} 的账号充值￥{listOfAmounts[active].amount}元？</p>
          </section>
        </>
      ),
      // closeOnAction: true,
      actions: [
        [
          {
            key: 'cancel',
            text: '取消',
            onClick: () => {
              handler.current?.close()
            },
          },
          {
            key: 'confirm',
            text: '确定',
            bold: true,
            onClick: () => {
              triggerTopUp()
              handler.current?.close()
            },
          },
        ],
      ],
    })
  }

  return (
    <div className="recharge-content-page">
      <NavBar title="充值" />

      <section className="recharge-content">
        <h3>账户充值</h3>
        <nav className="account-info">
          {
            userInfo.user_id ?
            <>
              <aside>
                <img className="head-portrait" src={userInfo.avatar} alt="" />
                <div>
                  <h3>{userInfo.user_nickname}</h3>
                  <p>ID:{userInfo.user_id}</p>
                </div>
              </aside>
              <span onClick={accountDialog}>切换账号</span>
            </> : 
            <button className="middle-btn primary-botton" onClick={accountDialog}>选择账户</button>
          }
        </nav>

        <h3>
          选择充值金额
          <span>*未成年人禁止充值消费</span>
        </h3>
        <p>平台不会以任何形式要求用户转账汇款、索要账号信息(账号、密码、验证码等)、向他人账号充值以及许诺充值返利活动，请大家提高警惕，谨防诈骗!</p>
        {
          hasActivity && dailyTopUp &&
          <div className="dailyTopUp" onClick={() => jumpPage('/top-up/total-top-up')}>
            <img src={dailyTopUp} alt="" />
            <p>每日充值，最高额外获得价值<span>{aggre_money}</span>元豪华专属礼包</p>
          </div>
        }
        <ul>
          {listOfAmounts.map((item, index) => (
            <li className={active === index ? 'active-btn' : ''} key={index} onClick={() => setActive(active => active = index)}>
              <img src={diamondPng} alt="" />
              <span>{item.diamond_num}</span>
              <span>￥{item.amount}.00</span>
            </li>
          ))}

          <li /><li />
        </ul>

        <h3>选择充值方式</h3>
        <nav className="recharge-methods">
          {methodOfPay.map((item, index) => (
            <div className={method === item.value ? 'active-btn img-btn' : 'img-btn'} key={index} onClick={() => setMethod(method => method = item.value)}>
              <img src={item.imgUrl} alt="" />
              <span>{item.name}</span>
            </div>
          ))}
          {/* <div className="img-btn active-btn">
            <img src={AlipayPng} alt="" />
            <span>支付宝</span>
          </div> */}
          {/* <div className="img-btn">
            <img src="/image/WeChat.png" alt="" />
            <span>微信</span>
          </div> */}
        </nav>

        <button disabled={!userInfo.user_id} className="big-btn" onClick={confirmPay}>确认支付<span>({listOfAmounts[active].amount}元)</span></button>
      </section>
    </div>
  )
}

export default Recharge