import { $get, $post } from "@/utils/request";
import { OrderData, AggreLevelData, GiftGetData } from '@/interface/recharge';

// 获取支付code
export const getPayMethod = (client_os:string) => {
  return $get(`/api/v1/pay/payment_method_query`, {client_os}).then((res: any) => res.data)
}

// 获取支付参数
export const postTopUp = (data: Object) => {
  return $post(`/v1/user/recharge_apply`, data).then((res: any) => res.data)
}

// 支付宝充值
export const postCreateOrder = (data: Object) => {
  return $post(`/api/v1/pay/create`, data).then((res: any) => res.data)
}

// 获取被充值的用户信息
export const getUserInfo = (user_id: number) => {
  return $get(`/v1/user/view/info`, { user_id }).then((res: any) => res.data)
}

// 充值商品列表 
export const getProductList = () => {
  return $get(`/v1/recharge/product_list?terminal=h5`).then((res: any) => res.data)
}

// 创建订单
export const createOrder = (data: OrderData) => {
  return $post(`/api/v1/pay/create`, data).then((res: any) => res.data)
}

// 累计礼包查询
export const getAggreLevelList = (params: AggreLevelData) => {
  return $get(`/v1/aggre/level/list`, params).then((res: any) => res.data)
}

// 每日礼包奖励领取
export const postGiftGet = (data: GiftGetData) => {
  return $post(`/v1/aggre/everyday/gift/get`, data).then((res: any) => res.data)
}
